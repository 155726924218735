import classNames from "classnames";
import type { Argument } from "classnames";
import type { ButtonProps } from "@circle-react-uikit/Button";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { Footer } from "./Footer";

export interface FooterFormProps {
  showFooter?: boolean;
  description?: string;
  primaryButtonProps: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  buttonsWrapperClassName?: string;
  className?: Argument;
}

export const FooterForm = ({
  showFooter = false,
  description = "",
  primaryButtonProps,
  secondaryButtonProps,
  buttonsWrapperClassName = "justify-end",
  className = "absolute",
}: FooterFormProps) => (
  <Footer hideFooter={!showFooter} className={className}>
    <div className="flex items-center justify-between">
      {description && (
        <div className="w-full">
          <Typography.LabelSm weight="semibold">
            {description}
          </Typography.LabelSm>
        </div>
      )}
      <div
        className={classNames(
          "flex w-full flex-row gap-x-4",
          buttonsWrapperClassName,
        )}
      >
        {secondaryButtonProps && <Button {...secondaryButtonProps} />}
        <Button {...primaryButtonProps} />
      </div>
    </div>
  </Footer>
);
