import { useEffect } from "react";
import { t } from "i18n-js";
import { Redirect, Route, Switch } from "react-router-dom";
import { usePlanStatus } from "@/react/hooks/usePlanStatus";
import { CheckIfPlanStatusSyncedWorkflowsApp } from "@circle-react-shared/UpgradePlan/CheckIfPlanStatusSyncedWorkflowsApp";
import { UPGRADE_PATHS } from "@circle-react-shared/UpgradePlan/constants";
import { CanDowngrade } from "./CanDowngrade";
import { ContactSupport } from "./ContactSupport";
import { Loader } from "./Loader";
import { PlanDetails } from "./PlanDetails";
import { Proration } from "./Proration";
import { TaxIdForm } from "./TaxIdForm";
import { useUpgradePlanContext } from "./UpgradePlanContext";
import { UpgradeSuccessful } from "./UpgradeSuccessful";
import { useUpgradePlanForm } from "./hooks/useUpgradePlanForm";

const i18nRoot = "pricing_plans.upgrade_modal";

export interface UpgradeFlowProps {
  handleOnSuccess: () => void;
  modal: any;
  subheading?: string;
  successButtonLabel?: string;
}

export const UpgradeFlow = ({
  handleOnSuccess,
  modal,
  subheading,
  successButtonLabel,
}: UpgradeFlowProps) => {
  const {
    plan,
    usedForWorkflows: isUsedForWorkflows,
    showPlanDetails: shouldShowPlanDetails,
    source,
    canCheckoutInstantly,
  } = useUpgradePlanContext();

  const { shouldShowProration } = usePlanStatus();
  const shouldShowProrationDetails = Boolean(shouldShowProration(plan));

  const { onSubmitHandler, changePlan, loadingMessage } = useUpgradePlanForm({
    usedForWorkflows: isUsedForWorkflows,
    showProration: shouldShowProrationDetails,
    canCollectTaxId: false,
    source,
  });

  useEffect(() => {
    if (canCheckoutInstantly) {
      onSubmitHandler();
    }
  }, [canCheckoutInstantly]);

  const initPath = () => {
    switch (true) {
      case shouldShowPlanDetails:
        return UPGRADE_PATHS.PLAN_DETAILS;
      case shouldShowProrationDetails:
        return UPGRADE_PATHS.PRORATION;
      default:
        return UPGRADE_PATHS.LOADING;
    }
  };

  return (
    <Switch>
      <Route exact path={UPGRADE_PATHS.PLAN_DETAILS}>
        <PlanDetails
          subheading={subheading}
          hideModal={modal.hide}
          onSubmitHandler={() => onSubmitHandler()}
        />
      </Route>
      <Route path={UPGRADE_PATHS.PRORATION}>
        <Proration
          hideModal={modal.hide}
          onSubmitHandler={() => onSubmitHandler()}
        />
      </Route>
      <Route path={UPGRADE_PATHS.TAX_ID_FORM}>
        <TaxIdForm hideModal={modal.hide} onSubmitHandler={onSubmitHandler} />
      </Route>
      <Route path={UPGRADE_PATHS.CAN_DOWNGRADE}>
        <CanDowngrade
          plan={plan}
          changePlan={changePlan}
          hideModal={modal.hide}
        />
      </Route>
      <Route exact path={UPGRADE_PATHS.LOADING}>
        <Loader
          title={loadingMessage.title}
          subtitle={loadingMessage.subtitle}
        />
      </Route>
      <Route exact path={UPGRADE_PATHS.WORKFLOW_APP_SYNCING}>
        <CheckIfPlanStatusSyncedWorkflowsApp planTier={plan.tier} />
      </Route>
      <Route exact path={UPGRADE_PATHS.ERROR}>
        <ContactSupport title={t([i18nRoot, "errors.general_error.title"])} />
      </Route>
      <Route exact path={UPGRADE_PATHS.UPGRADE_SUCCESSFUL}>
        <UpgradeSuccessful
          buttonLabel={successButtonLabel}
          onSuccess={handleOnSuccess}
          unlockedFeatures={plan?.main_features_summarized}
        />
      </Route>
      <Redirect to={initPath()} />
    </Switch>
  );
};
