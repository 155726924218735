import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import { Icon } from "@circle-react-shared/Icon";
import { UPGRADE_PATHS } from "@circle-react-shared/UpgradePlan/constants";
import { useCommunityCurrentUsageStats } from "@circle-react-shared/Workflows/hooks/useCommunityCurrentUsageStats";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

const i18nRoot = "pricing_plans.upgrade_modal";
export const CheckIfPlanStatusSyncedWorkflowsApp = ({ planTier }) => {
  const history = useHistory();

  useCommunityCurrentUsageStats({
    refetchInterval: 5000,
    onSuccess: response => {
      if (response.plan_tier === planTier) {
        history.push(UPGRADE_PATHS.UPGRADE_SUCCESSFUL);
      }
    },
  });

  return (
    <Modal.Body>
      <div className="mt-28 text-center">
        <div className="m-auto mb-9 w-28">
          <Icon className="text-dark !h-full !w-full" type="loader" />
        </div>

        <div className="mb-28">
          <Typography.TitleLg color="text-darkest !font-bold">
            {t([i18nRoot, "loading.workflow_app_syncing"])}
          </Typography.TitleLg>
        </div>
      </div>
    </Modal.Body>
  );
};

CheckIfPlanStatusSyncedWorkflowsApp.propTypes = {
  planTier: PropTypes.string,
};
