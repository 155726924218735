import PropTypes from "prop-types";
import { t } from "i18n-js";
import { ErrorDetails } from "./ErrorDetails";

export const ContactSupport = ({ title, errorMessage }) => (
  <ErrorDetails
    title={title}
    errorMessage={errorMessage}
    onClick={() => window.open("mailto:support@circle.so", "emailWindow")}
    buttonLabel={t("pricing_plans.upgrade_modal.actions.contact_support")}
  />
);

ContactSupport.propTypes = {
  title: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};
