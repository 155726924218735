import PropTypes from "prop-types";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";

export const ErrorDetails = ({ title, errorMessage, buttonLabel, onClick }) => (
  <>
    <Modal.Body>
      <div className="mt-16 text-center">
        <div className="mb-10">
          <Icon type="68-error-red" size={68} className="!w-16" />
        </div>

        {title && (
          <div className="mb-3">
            <Typography.TitleMd>{title}</Typography.TitleMd>
          </div>
        )}

        {errorMessage && (
          <div className="mb-14">
            <Typography.LabelMd color="text-default">
              <SanitizeHTMLString content={errorMessage} />
            </Typography.LabelMd>
          </div>
        )}
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="circle" large full onClick={onClick}>
        {buttonLabel}
      </Button>
    </Modal.Footer>
  </>
);

ErrorDetails.propTypes = {
  title: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
