import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Link } from "react-router-dom";
import { planSettingsPath } from "@/react/helpers/urlHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { getNextTier } from "@circle-react/helpers/planHelpers";
import { CurrencySelector } from "@circle-react-shared/Billing/CurrencySelector";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { useUpgradePlanContext } from "./UpgradePlanContext";

const i18nRoot = "pricing_plans.upgrade_modal";

export const PlanDetails = ({
  subheading = "",
  hideModal,
  onSubmitHandler,
}) => {
  const {
    billingCurrency,
    setBillingCurrency,
    plan,
    shouldShowCurrencySelection,
  } = useUpgradePlanContext();
  const { currentCommunity } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity;
  const learnMoreRedirect = () => (window.location.href = planSettingsPath());
  const displayIntervalText = `/ ${plan.display_interval.toLowerCase()}`;
  const nextTier = getNextTier(currentPlanTier);
  const everythingInCopy =
    nextTier === plan.tier
      ? `everything_in_${plan.tier}`
      : "everything_in_your_plan_plus";

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {t([i18nRoot, "title"], { plan: plan.display_name })}
        </Modal.Title>

        <Modal.HeaderActions>
          <Modal.CloseButton onClick={hideModal} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body>
        {subheading && (
          <>
            <Typography.LabelSm weight="semibold" color="text-default">
              {subheading}
            </Typography.LabelSm>
            <div className="border-primary mt-5 border-b" />
          </>
        )}
        <div className="flex flex-row justify-between">
          <div>
            <div className="mt-3.5">
              <Typography.LabelSm weight="semibold" color="text-default">
                {t([i18nRoot, "plan_name"], { plan: plan.display_name })}
              </Typography.LabelSm>
            </div>
            <div className="mt-1 flex items-baseline gap-1">
              <Typography.TitleXl>
                {plan.amount_without_currency}
              </Typography.TitleXl>
              <Typography.LabelSm weight="medium" color="text-default">
                {displayIntervalText}
              </Typography.LabelSm>
            </div>
            <div className="mt-2.5">
              <Typography.LabelSm>
                {t([i18nRoot, `${plan.tier}_description`])}
              </Typography.LabelSm>
            </div>
          </div>
          {shouldShowCurrencySelection && (
            <CurrencySelector
              billingCurrency={billingCurrency}
              onCurrencyChange={currency => setBillingCurrency(currency)}
            />
          )}
        </div>

        <div className="border-primary mt-5 border-b" />

        <div className="mt-6">
          <Typography.LabelLg weight="semibold">
            {t([i18nRoot, everythingInCopy])}
          </Typography.LabelLg>
        </div>

        <ul className="mt-6 flex list-none flex-col gap-5">
          {plan?.main_features?.map((feature, index) => (
            <li className="flex items-center gap-3" key={index}>
              <Icon type="20-checkmark-blue" size={20} className="!h-5 !w-5" />
              <Typography.LabelMd weight="medium">{feature}</Typography.LabelMd>
            </li>
          ))}
        </ul>
        <div className="mt-5">
          <Link onClick={learnMoreRedirect}>
            <Typography.LabelMd weight="medium" color="text-default">
              {t([i18nRoot, "learn_more"])}
            </Typography.LabelMd>
          </Link>
        </div>
      </Modal.Body>
      <Modal.Footer className="mt-5">
        <Button variant="circle" large full onClick={onSubmitHandler}>
          {t([i18nRoot, "actions.upgrade"], { plan: plan.display_name })}
        </Button>
      </Modal.Footer>
    </>
  );
};

PlanDetails.propTypes = {
  subheading: PropTypes.string,
  plan: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
};
