import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { accountSubscriptionApi } from "@/react/api/accountSubscriptionApi";
import { UPGRADE_PATHS } from "../constants";

interface UseAccountSubscriptionApiOptions {
  usedForWorkflows?: boolean;
  source?: string;
}

interface UpdateSubscriptionParams {
  planId: string;
  planCouponId?: string; // Make this optional if it's not required for every call
}

export const useAccountSubscriptionApi = ({
  usedForWorkflows = false,
  source,
}: UseAccountSubscriptionApiOptions = {}) => {
  const history = useHistory();

  const { mutate: updateSubscription } = useMutation(
    ({ planId, planCouponId }: UpdateSubscriptionParams) =>
      accountSubscriptionApi.update(planId, planCouponId, source),
    {
      onSuccess: () => {
        if (usedForWorkflows) {
          history.push(UPGRADE_PATHS.WORKFLOW_APP_SYNCING);
        } else {
          history.push(UPGRADE_PATHS.UPGRADE_SUCCESSFUL);
        }
      },
      onError: () => {
        history.push(UPGRADE_PATHS.ERROR);
      },
    },
  );

  return {
    updateSubscription,
  };
};
