import { useEffect, useState } from "react";
import { supportedCurrencies } from "@circle-react/config/Currencies";

interface CurrencySelectorProps {
  billingCurrency: string | null;
  onCurrencyChange?: (currency: string) => void;
}

export const CurrencySelector = ({
  billingCurrency,
  onCurrencyChange = () => {},
}: CurrencySelectorProps) => {
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);

  useEffect(() => {
    if (billingCurrency) {
      setSelectedCurrency(billingCurrency.toUpperCase());
    }
  }, [billingCurrency]);

  const handleCurrencyChange = (currency: string) => {
    setSelectedCurrency(currency);
    onCurrencyChange(currency);
  };

  const options = Object.entries(supportedCurrencies).map(
    ([currency, { code, flag }]) => (
      <option key={currency} value={code}>
        {flag} {code}
      </option>
    ),
  );

  return selectedCurrency ? (
    <div className="mt-3">
      <div className="mb-8 mt-2 md:mb-0 md:mr-2 md:pr-2">
        <div className="relative block text-left">
          <select
            className="border-primary block appearance-none rounded-md border bg-white py-2 pl-3 pr-8 text-base leading-6 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none"
            onChange={e => handleCurrencyChange(e.target.value)}
            value={selectedCurrency}
          >
            {options}
          </select>
          <div className="border-primary pointer-events-none absolute inset-y-0 right-0 flex items-center px-2" />
        </div>
      </div>
    </div>
  ) : null;
};
