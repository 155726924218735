import type { ReactNode } from "react";
import classNames from "classnames";
import type { Argument } from "classnames";

export interface FooterProps {
  children: ReactNode;
  hideFooter?: boolean;
  className?: Argument;
}

export const Footer = ({
  children,
  hideFooter,
  className = "absolute",
}: FooterProps) => {
  if (hideFooter) return null;

  return (
    <div
      className={classNames(
        "bg-primary border-primary bottom-0 left-0 w-full justify-center border-t",
        className,
      )}
    >
      <div className="mx-auto max-w-screen-md px-6 py-4">{children}</div>
    </div>
  );
};
