import { useQuery } from "react-query";
import { planApi } from "@/react/api";

export interface UseFetchPlanProps {
  planTier?: string;
  planId?: string;
  billingCurrency?: string;
  locale?: string;
}

export const useFetchPlan = ({
  planTier = "",
  planId = "",
  billingCurrency = "",
  locale = "",
}: UseFetchPlanProps) => {
  const {
    data: plan,
    isLoading: isPlanLoading,
    error: errorFetchingPlan,
  } = useQuery(
    ["plan", "fetch", planTier, planId, billingCurrency, locale],
    () =>
      planApi.fetch({
        tier: planTier,
        id: planId,
        billing_currency: billingCurrency,
        locale: locale,
      }),
  );

  return {
    plan,
    isPlanLoading,
    errorFetchingPlan,
  };
};
